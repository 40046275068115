<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 650px; margin: 5% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<table class="base-detail">
						<caption>결제정보</caption>
						<colgroup>
							<col width="20%" />
							<col width="30%" />
							<col width="20%" />
							<col width="30%" />
						</colgroup>
						<tbody>
							<tr>
								<th>승인번호</th>
								<td>{{ item.approvalNo }}</td>
								<th>거래일시</th>
								<td>{{ item.approvalDate | transDateTime }}</td>
							</tr>
							<tr>
								<th>카드번호</th>
								<td>{{ item.cardNumber | makeCardNumber }}</td>
								<th>카드종류</th>
								<td>{{ item.cardType }}</td>
							</tr>
							<tr>
								<th>거래종류</th>
								<td>신용거래</td>
								<th>할부개월</th>
								<td>{{ item.installment }}</td>
							</tr>
						</tbody>
					</table>
					<table class="base-detail">
						<caption>구매정보</caption>
						<colgroup>
							<col width="20%" />
							<col width="30%" />
							<col width="20%" />
							<col width="30%" />
						</colgroup>
						<tbody>
							<tr>
								<th>주문번호</th>
								<td>{{ item.tradeNo }}</td>
								<th>공급가</th>
								<td>{{ item.supplyAmount | makeComma }}원</td>
							</tr>
							<tr>
								<th>상품명</th>
								<td>{{ item.paymentProductName }}</td>
								<th>부가세</th>
								<td>{{ item.vatAmount | makeComma }}원</td>
							</tr>
							<tr>
								<th>구매자 연락처</th>
								<td>{{ item.buyerContact | makeTell }}</td>
								<th>합계금액</th>
								<td>{{ item.totalPaymentAmount | makeComma }}원</td>
							</tr>
						</tbody>
					</table>
					<table class="base-detail">
						<caption>이용상점정보</caption>
						<colgroup>
							<col width="20%" />
							<col width="80%" />
						</colgroup>
						<tbody>
							<tr>
								<th>판매자상호</th>
								<td>{{ item.salesName }}</td>
							</tr>
							<tr>
								<th>사업자</th>
								<td>{{ item.salesContact | makeTell }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-center mb-10">
					<v-btn @click="print" class="primary mr-10">프린트</v-btn>
					<v-btn @click="sms" class="success2 mr-10">영수증 문자 전송</v-btn>
					<v-btn @click="close" class="gray">닫기</v-btn>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default{
		name: 'AgentPopupItem'
		,props: ['item']
		,data: function(){
			return {
				program: {
					name: '영수증'
				}
			}
		}
		,methods: {
			close: function(){
				this.$emit('setOverlay', false)
			}
			,sms: function(){
				alert(this.$language.common.ready)
			}
			,print: function(){
				alert(this.$language.common.ready)
			}
		}
	}
</script>
<style>
	.base-detail { width: 100%; border: 1px solid #ddd; padding: 0;}
	.base-detail caption { padding: 10px; text-align: left; font-weight: bold; font-size: 1.2em; border-bottom: 2px solid black;}
	
	.base-detail th, .base-detail td { padding: 10px; margin: 0px !important; font-size: 12px;}
	.base-detail th { text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd; background-color: #eee; }
	.base-detail td { border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;}
</style>



